import { Box, Container, Typography } from "@mui/material";
import Footer from "../../elements/Footer";
import Header from "../../elements/Header";
import SubHeader from "../../elements/SubHeader/SubHeader";
import BlogPostCard from "../../elements/Blog/BlogPostCard";
import "./blog.css";
import FaqSection from "../../elements/Faq/FaqSection";
import { POSTS } from "../../helpers/BlogPosts";
import SubscriptionInput from "../../elements/Subscription/SubscriptionInput";
import { useState } from "react";
import { createSlug } from "../../helpers/utils";
const Blog = () => {
  const [email, setEmail] = useState("");
  const subtitle = (
    <Box className="blog_subtitle">
      Learn more about Cache product updates, personal finance, and ways to
      optimize your finances.
    </Box>
  );

  const posts = POSTS.map((post, index) => (
    <BlogPostCard
      key={index}
      title={post.title}
      author={post.author}
      image={post.image}
      description={post.description}
      slugTitle={createSlug(post.title)}
    />
  ));

  return (
    <div>
      <Header position="sticky" />
      <SubHeader title_1="Cache" title_2=" Blog" subtitle={subtitle} />
      <Box className="whiteBox">
        <Box>
          <Container>
            <div className="layout_posts">
              <SubscriptionInput
                email={email}
                setEmail={setEmail}
                subscriptionBody={{
                  referrer: window.document.referrer || "N/A",
                  blogId: 0,
                  email: email,
                }}
              />
              <Typography className="main_title_post" id="posts">
                All Posts
              </Typography>
              {posts}
            </div>
          </Container>
        </Box>
      </Box>
      <Box className="blackBox" textAlign="center">
        <FaqSection />
      </Box>
      <Footer />
    </div>
  );
};

export default Blog;
