import { Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { useLazyGetUserRegistrationStepQuery } from "../api/apiAuth";
import { useCallback, useContext, useEffect, useState } from "react";
import { REGISTRATION_STEPS } from "../helpers/redirectToRegistrationStep";
import Loader from "../elements/Loader/Loader";
import { signOut } from "firebase/auth";
import notification from "../elements/ToastNotifications/ToastNotifications";
import { AuthContext } from "../context/AuthContext";
import { useMixpanelPublic } from "../helpers/mixpanel";
import { reset } from "../redux/sliceAuth";
import { resetUserAutomation } from "../redux/sliceUserAutomation";
import { useDispatch } from "react-redux";

const PrivateRoutesLayout = () => {
  const context = useContext(AuthContext);
  const { user } = context;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanelPublic();
  const [loading, setLoading] = useState(true);
  const [getRegistrationStep, registrationStepData] =
    useLazyGetUserRegistrationStepQuery();
  useEffect(() => {
    if (!registrationStepData.data) {
      return;
    }

    const prevLocation = location.state?.from;

    const { step } = registrationStepData.data;

    switch (step) {
      case REGISTRATION_STEPS.INTRO:
      case REGISTRATION_STEPS.CACHE_PRIVATE_POLICY:
      case REGISTRATION_STEPS.SET_AUTOMATIONS:
      case REGISTRATION_STEPS.PERSONAL_DATA_ADDRESS:
      case REGISTRATION_STEPS.PERSONAL_DATA_BIRTHDAY:
      case REGISTRATION_STEPS.PERSONAL_DATA_SSN:
      case REGISTRATION_STEPS.VERIFY_EMAIL:
      case REGISTRATION_STEPS.NOT_DEFINED:
        navigation("/signup");
        break;
      case REGISTRATION_STEPS.VERIFY_DWOLLA_PENDING:
        navigation("/verify-account");
        break;
      case REGISTRATION_STEPS.VERIFY_DWOLLA_RETRY:
        navigation("/signup-retry-verify");
        break;
      case REGISTRATION_STEPS.VERIFY_DWOLLA_FAILED:
        navigation("/signup-unable-verify");
        break;
      case REGISTRATION_STEPS.COMPLETED:
        navigation(prevLocation || location?.pathname || "/automations");
        break;
      case REGISTRATION_STEPS.REMOVED:
        signOut(auth)
          .then(() => {
            navigation("/signup");
            notification({
              message: "Your account has been removed",
              type: "error",
            });
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      default:
        navigation("/login");
        break;
    }
    setLoading(false);
  }, [registrationStepData.data]);

  useEffect(() => {
    if (registrationStepData.isError) {
      setLoading(false);
      signOut(auth)
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [registrationStepData.isError]);

  const handleRegistrationStep = useCallback(async () => {
    try {
      const idToken = await user?.getIdToken();
      if (!idToken) {
        return;
      }
      await getRegistrationStep({ idToken }, false);
      setLoading(false);
    } catch (error) {
      const isRequestFailed = `${error}`.includes(
        "auth/network-request-failed",
      );
      if (isRequestFailed) {
        try {
          await mixpanel("Network_Request_Failed", {
            userName: user?.displayName || "N/A",
            userEmail: user?.email || "N/A",
            pageLocation: window.location?.pathname || "N/A",
            layout: "Private",
            errorMsg: `${error}`,
          });
        } catch (error) {
          console.log("mixpanel event failed");
        }
        try {
          await signOut(auth);
          dispatch(reset());
          dispatch(resetUserAutomation());
          navigation("/");
        } catch (error) {
          await mixpanel("Signout_Error_Private_Route", {
            errorMsg: `${error}`,
          });
        }
      }
    }
  }, [context]);

  useEffect(() => {
    handleRegistrationStep();
  }, [context]);

  if (!auth.currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) {
    return <Loader loading={true} />;
  }

  return <Outlet />;
};

export default PrivateRoutesLayout;
