import { api } from "./api";

const apiPlaid = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkToken: build.query({
      query: ({ accessToken }: { accessToken?: string | null }) =>
        accessToken
          ? `/plaid/get-link-token?token=${accessToken}`
          : "/plaid/get-link-token",
    }),
    auth: build.mutation({
      query: ({ publicToken, firebaseTkn, metadata }) => ({
        url: "/plaid/auth",
        method: "POST",
        body: { publicToken, metadata },
        headers: {
          Authorization: "Bearer " + firebaseTkn,
        },
      }),
    }),
    exchangePublicToken: build.mutation({
      query: (body) => ({
        url: "/plaid/exchange-public-token",
        method: "POST",
        body: body,
      }),
    }),
    getUserAccounts: build.query({
      query: ({ idToken }) => ({
        url: `/user/accounts`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    getAutomations: build.query({
      query: ({ idToken }) => ({
        url: `/automations`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    postAutomation: build.mutation({
      query: ({ body, idToken, isOnboarding }) => ({
        url: `/automations${isOnboarding ? "/onboarding" : ""}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
        method: "POST",
        body: body,
      }),
    }),
    updateAutomationDonation: build.mutation({
      query: ({ body, idToken, id }) => ({
        url: `/automations/updateAutomation/${id}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
        method: "PUT",
        body: body,
      }),
    }),

    deleteAutomations: build.query({
      query: ({ idToken, id, answerList }) => ({
        url: `/automations/${id}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
        body: { poll: answerList },
        method: "PATCH",
      }),
    }),

    modifyAutomation: build.mutation({
      query: ({ body, idToken, automationId, isOnboarding }) => ({
        url: `/automations/${automationId}${isOnboarding ? "/onboarding" : ""}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
        method: "PUT",
        body: body,
      }),
    }),

    disconnectAccount: build.query({
      query: ({ idToken, id }) => ({
        url: `/accounts/${id}/unlink`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
        method: "PATCH",
      }),
    }),
    activatePlaidItem: build.mutation({
      query: ({ idToken, accessToken }) => ({
        url: "/accounts/activate-item",
        headers: {
          Authorization: "Bearer " + idToken,
        },
        method: "PATCH",
        body: { accessToken },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useModifyAutomationMutation,
  useLazyGetLinkTokenQuery,
  useAuthMutation,
  usePostAutomationMutation,
  useExchangePublicTokenMutation,
  useLazyGetUserAccountsQuery,
  useLazyGetAutomationsQuery,
  useLazyDeleteAutomationsQuery,
  useLazyDisconnectAccountQuery,
  useActivatePlaidItemMutation,
  useUpdateAutomationDonationMutation,
} = apiPlaid;
