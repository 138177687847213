import { useState, useEffect, useContext } from "react";
import Button from "../../elements/Button/Button";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";
import delete_icon from "../../assets/delete_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./editAutomation.css";
import AccountCard from "../../elements/AccountCard/AccountCard";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { getAuth } from "firebase/auth";
import {
  resetSelectedAutomation,
  setCheckingAcc,
  setSavingsAcc,
} from "../../redux/sliceUserAutomation";
import closeBtn from "../../assets/closeBtn.png";
import PlaidAuth from "../Plaid/PlaidAuth";
import {
  setCheckingAccountList,
  setSavingsAccountList,
} from "../../redux/sliceAccounts";
import arrows from "../../assets/arrows_vertical.png";
import arrows_red from "../../assets/arrows_red_vertical.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteAutomationsQuery,
  useLazyGetUserAccountsQuery,
  useModifyAutomationMutation,
} from "../../api/apiPlaid";
import DeleteQuestionsModal from "../../elements/Modals/DeleteQuestionsModal";
import BackButton from "../../elements/BackButton/BackButton";
import BalanceInput from "../../elements/BalanceInput/BalanceInput";
import BankCard from "../../elements/BankCard/BankCard";
import NotificationBar from "../../elements/NotificationBar/NotificationBar";
import Dialog from "@mui/material/Dialog";
// @ts-ignore
import { AuthContext } from "../../context/AuthContext";
import { useLazyGetAutomationsDataQuery } from "../../api/apiDashboard";
import { useMixpanel } from "../../helpers/mixpanel";
import { AccountType } from "../../helpers/types";
import AccountLoadingCard from "../../elements/AccountLoadingCard/AccountLoadingCard";
import { WEALTHFRONT_INSTITUTION } from "../../helpers/constants";

const EditAutomation = () => {
  const [showCheckingAccountsModal, setShowCheckingAccountsModal] =
    useState(false);
  const [showSavingsAccountsModal, setShowSavingsAccountsModal] =
    useState(false);
  const [showDeleteAutomationModal, setShowDeleteAutomationModal] =
    useState(false);

  const context = useContext(AuthContext);
  const { user } = context;
  const { automationId } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();

  const [modifyAutomation, modifyResult] = useModifyAutomationMutation();
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [getAutomationsReq, getAutomationsRes] =
    useLazyGetAutomationsDataQuery();
  const [deleteAutomation, deleteResult] = useLazyDeleteAutomationsQuery();
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [isCheckingAccountError, setIsCheckingAccountError] = useState(false);
  const [accountErrorType, setAccountErrorType] = useState("");
  const [accountType, setAccountType] = useState({
    type: AccountType.CHECKING,
    clicked: false,
  });
  const [typeAcc, setTypeAcc] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);

  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    setAccountLoadMsg(errorMsg);
    setAccountErrorType(type);
    setIsCheckingAccountError(true);
  };

  const [selectCheckingAcc, setSelectCheckingAcc] = useState<any>();
  const [selectSavingsAcc, setSelectSavingsAcc] = useState<any>();

  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );

  //find required automation by id, apply necessary data
  useEffect(() => {
    if (getAutomationsRes.data) {
      const automationsData = getAutomationsRes?.data?.data.automations;
      if (automationsData) {
        const editingAutomation = automationsData.find(
          (automation: any) => automation.id === automationId,
        );

        setValue(editingAutomation?.fixedValue);
        setSelectCheckingAcc(editingAutomation?.checkingAccount);
        setSelectSavingsAcc(editingAutomation?.savingAccount);
        setIsLoadingData(false);
      }
    }
  }, [getAutomationsRes.data]);

  //make request to backend for all automations
  const getAccounts = () => {
    if (!user) {
      return;
    }
    user.getIdToken().then(async (idToken: string) => {
      await getAccountsList({ idToken });
    });
  };

  const getAutomations = () => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    user.getIdToken().then(async (idToken: string) => {
      await getAutomationsReq({ idToken });
      setIsLoading(false);
    });
  };

  //add accounts to redux state
  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      // Checking account of Wealthfront bank is like savings account for the automation
      accountsListResult?.data?.data.forEach((item: any) => {
        if (
          item.subtype === "savings" ||
          (item.subtype === AccountType.CHECKING &&
            item.institution.id === WEALTHFRONT_INSTITUTION)
        ) {
          savingsAccounts.push(item);
        } else if (item.subtype === AccountType.CHECKING) {
          checkingAccounts.push(item);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult.data]);

  //request all automations
  useEffect(() => {
    if (!getAutomationsRes.data) {
      getAutomations();
      getAccounts();
    }
  }, [getAutomationsRes.data]);

  const deleteAutomationHandler = (answerList: any) => {
    const { user } = context;
    if (!user) {
      navigation("/login");
    } else {
      user.getIdToken().then(async (idToken: string) => {
        const id = automationId;
        await deleteAutomation({ idToken, id, answerList }).then((rez) => {
          mixpanel(
            "Automation_delete_reasons_selected",
            {
              "Reasons selected": answerList,
            },
            idToken,
          );
          mixpanel("Automation_deleted", {}, idToken);
          notification({
            message: "Automation deleted.",
            type: "delete",
          });
          dispatch(resetSelectedAutomation());
          navigation("/automations");
        });
      });
    }
  };

  const onBackHandler = () => {
    navigation(-1);
  };

  const handleModalOpen = (type: string) => {
    document.body.classList.remove("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(true);
      mixpanel("Opened_Checking_List");
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(true);
      mixpanel("Opened_Savings_List");
    }
    setAccountLoadMsg("");
    setAccountErrorType("");
  };

  const handleModalClose = (type: string) => {
    document.body.classList.add("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(false);
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(false);
    }
  };

  const verifyAccountsSubtype = (accounts: any[], institution: any) => {
    if (!Array.isArray(accounts)) {
      return;
    }

    const isAccountTypeMatch = accounts.some((account) => {
      return (
        account?.subtype === typeAcc ||
        (account?.subtype === AccountType.CHECKING &&
          institution?.institution_id === WEALTHFRONT_INSTITUTION)
      );
    });

    if (isAccountTypeMatch) {
      return;
    }

    //banner that user didnt connect suitable account
    if (typeAcc === AccountType.CHECKING) {
      setAccountErrorType(AccountType.CHECKING);
      setIsCheckingAccountError(true);
    }
    if (typeAcc === "savings") {
      setAccountErrorType("savings");
    }
  };

  function ModalAccountsList({
    accountsList,
    setAccount,
    open,
    onClose,
    title,
    relationType,
  }: any) {
    const onSelectHandler = (item: any) => {
      if (!item.isUsedInAutomation) {
        setAccount(item);
        if (relationType === AccountType.CHECKING) {
          mixpanel("Connected_checking_account", { Banks: item });
        } else if (relationType === "savings") {
          mixpanel("Connected_savings_account", { Banks: item });
        }
        onClose();
      } else {
        return;
      }
    };
    const prePopulateAccount = (accounts: any) => {
      if (
        accounts[0]?.subtype === AccountType.CHECKING &&
        isCheckingCardClicked
      ) {
        setSelectCheckingAcc(accounts[0]);
      }
      if (
        accounts[1]?.subtype === AccountType.CHECKING &&
        isCheckingCardClicked
      ) {
        setSelectCheckingAcc(accounts[1]);
      }
      console.log(accounts[0]?.subtype, isSavingCardClicked);

      if (accounts[0]?.subtype === AccountType.SAVING && isSavingCardClicked) {
        setSelectSavingsAcc(accounts[0]);
      }
      if (accounts[1]?.subtype === AccountType.SAVING && isSavingCardClicked) {
        setSelectSavingsAcc(accounts[1]);
      }
    };

    return (
      <Dialog
        onClose={onClose}
        open={open}
        classes={{
          root: "add-automation-dialog",
          paper: "add-automation-dialog-paper",
        }}
      >
        <div className="closeModalBtn">
          <img src={closeBtn} alt="close button" onClick={onClose} />
        </div>
        <div className="text-center" style={{ width: "100%" }}>
          <PlaidAuth
            accountType={`Add New ${title}`}
            typeBtn="modalPlaid-btn"
            success={(accounts: any) => {
              prePopulateAccount(accounts);
              if (relationType === AccountType.CHECKING) {
                if (accounts.length > 0) {
                  if (accounts[0].institution.id === WEALTHFRONT_INSTITUTION) {
                    setAccountErrorType(AccountType.CHECKING);
                    setIsCheckingAccountError(true);
                  }
                }
              }

              getAccounts();
              handleModalClose(AccountType.CHECKING);
              handleModalClose(AccountType.SAVING);
            }}
            accountLoadErrorCallback={accountLoadErrorCallback}
            relationType={relationType}
            preConnectedAccountsCB={verifyAccountsSubtype}
          />
        </div>
        <div className="modalAccountsList__title">{`Available ${title}s`}</div>
        <div className="modalAccountsList__cardsHolder">
          {accountsList?.map((item: any) => (
            <BankCard
              key={item.plaidAccountId}
              item={item}
              selectHandler={onSelectHandler}
            />
          ))}
        </div>
      </Dialog>
    );
  }

  const onChangeHandler = (e: any) => {
    const val = e.target.value;
    setValue(val);
    setError(false);
  };

  const onNextHandler = () => {
    const { user } = context;
    if (!value) {
      setError(true);
      return;
    }
    const automationData = {
      checkingAccountId: selectCheckingAcc.id,
      checkingAccountPlaidId: selectCheckingAcc.plaidAccountId,
      savingAccountId: selectSavingsAcc.id,
      savingAccountPlaidId: selectSavingsAcc.plaidAccountId,
      fixedValue: +value,
    };
    if (!user) {
      navigation("/login");
    } else {
      setIsLoading(true);
      user.getIdToken().then(async (idToken: string) => {
        await modifyAutomation({
          body: automationData,
          idToken,
          automationId,
        }).then((res: any) => {
          if (res?.error?.data?.message === "CREATED_INACTIVE_AUTOMATION") {
            notification({
              message: "Created inactive automation.",
              type: "success",
            });
            navigation("/automations");
          }
          if (res?.data?.status === "SUCCESS") {
            notification({
              message: "Automation updated successfully.",
              type: "success",
            });
            navigation("/automations");
          }
          setIsLoading(false);
        });
      });
    }
  };

  const onClickCheckingAccountCard = () => {
    setAccountType({ type: AccountType.CHECKING, clicked: true });
    setTypeAcc(AccountType.CHECKING);
    handleModalOpen(AccountType.CHECKING);
  };

  const onClickSavingAccountCard = () => {
    setAccountType({ type: AccountType.SAVING, clicked: true });
    setTypeAcc("savings");
    handleModalOpen(AccountType.SAVING);
  };

  const isCheckingCardClicked =
    accountType.type === AccountType.CHECKING && accountType.clicked;
  const isSavingCardClicked =
    accountType.type === AccountType.SAVING && accountType.clicked;

  return (
    <div>
      <HeaderMenu />
      <NotificationBar />
      <div className="container edit-automation-container-wrapper">
        <div className="backButton">
          <BackButton onBack={onBackHandler} />
        </div>
        <div className="edit__titleHolder">
          <div className="page__title custom_mobile">Edit your automation.</div>
          <div className="page__subtitle automation__description">
            Click on any field to edit.
          </div>
        </div>
        <div
          className="automation__container edit-automation__container"
          style={{ flex: "0" }}
        >
          <div className="connectionButton__holder">
            <div className="automations__checkingCards">
              {isLoadingData ? (
                <AccountLoadingCard />
              ) : (
                <AccountCard
                  active={true}
                  errorCard={accountErrorType === AccountType.CHECKING}
                  item={selectCheckingAcc}
                  type="Checking account"
                  reconnectionFlow={true}
                  showOnlyDisconnectedIcon={true}
                  disconnected={
                    selectCheckingAcc?.status === "reconnection_need"
                      ? true
                      : false
                  }
                  selectHandler={onClickCheckingAccountCard}
                  editFlow={true}
                />
              )}
              {isCheckingAccountError &&
                isCheckingCardClicked &&
                accountErrorType === AccountType.CHECKING && (
                  <div className="userName__errorMassage error__message account-exist mobile-error">
                    The account that you selected is not a checking account.
                    Please try again. If necessary, connect to another
                    institution.
                  </div>
                )}
              {accountLoadMsg === "STRANGER_ACCOUNT" &&
                isCheckingCardClicked &&
                accountErrorType === AccountType.CHECKING && (
                  <div className="userName__errorMassage error__message account-exist mobile-error">
                    The selected account is already associated with another
                    Cache user. Please select a different account to continue.
                  </div>
                )}
              <div className="balanceInput__holder">
                <BalanceInput
                  error={error}
                  isdisabled={!(selectCheckingAcc && selectSavingsAcc)}
                  value={value}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              {error && (
                <div className="userName__errorMassage error__message">
                  Please set your fixed balance.
                </div>
              )}
            </div>
            <div className="arrows__icon">
              {selectCheckingAcc?.status === "reconnection_need" ||
              selectSavingsAcc?.status === "reconnection_need" ? (
                <img src={arrows_red} alt="Arrows Icon" />
              ) : (
                <img src={arrows} alt="Arrows Icon" />
              )}
            </div>
            <div className="automations__savingsCards">
              {isLoadingData ? (
                <AccountLoadingCard />
              ) : (
                <AccountCard
                  active={true}
                  errorCard={accountErrorType === "savings"}
                  item={selectSavingsAcc}
                  type="Savings account"
                  reconnectionFlow={true}
                  showOnlyDisconnectedIcon={true}
                  selectHandler={onClickSavingAccountCard}
                  disconnected={
                    selectSavingsAcc?.status === "reconnection_need"
                      ? true
                      : false
                  }
                  editFlow={true}
                />
              )}
              {accountErrorType === "savings" && isSavingCardClicked && (
                <div className="userName__errorMassage error__message account-exist mobile-error">
                  The account that you selected is not a savings account. Please
                  try again. If necessary, connect to another institution.
                </div>
              )}
              {accountLoadMsg === "STRANGER_ACCOUNT" &&
                isSavingCardClicked &&
                accountErrorType === "savings" && (
                  <div className="userName__errorMassage error__message account-exist mobile-error">
                    The selected account is already associated with another
                    Cache user. Please select a different account to continue.
                  </div>
                )}
            </div>
          </div>
          {isCheckingAccountError &&
            isCheckingCardClicked &&
            accountErrorType === AccountType.CHECKING && (
              <div className="userName__errorMassage error__message account-exist desktop-error">
                The account that you selected is not a checking account. Please
                try again. If necessary, connect to another institution.
              </div>
            )}
          {accountErrorType === "savings" && isSavingCardClicked && (
            <div className="userName__errorMassage error__message account-exist desktop-error">
              The account that you selected is not a savings account. Please try
              again. If necessary, connect to another institution.
            </div>
          )}
          {accountLoadMsg === "STRANGER_ACCOUNT" &&
            [AccountType.CHECKING, "savings"].includes(accountErrorType) && (
              <div className="userName__errorMassage error__message account-exist desktop-error">
                The selected account is already associated with another Cache
                user. Please select a different account to continue.
              </div>
            )}
        </div>

        <button
          className="deleteAutomation__btn"
          onClick={() => {
            mixpanel("Start_automation_delete_flow");
            setShowDeleteAutomationModal(true);
          }}
        >
          <div className="deletebtn_title">Delete Automation</div>
          <div className="deleteAutomation__btn-icon">
            <img src={delete_icon} alt="delete icon" />
          </div>
        </button>
        <div className="confirmButton__holder">
          <Button
            disabled={isLoading}
            onClick={onNextHandler}
            type={"primary-btn"}
          >
            Save
          </Button>
        </div>
      </div>

      {showCheckingAccountsModal && (
        <ModalAccountsList
          title="Checking Account"
          accountsList={checkingAccounts}
          open={showCheckingAccountsModal}
          relationType={AccountType.CHECKING}
          onClose={() => {
            handleModalClose(AccountType.CHECKING);
          }}
          setAccount={setSelectCheckingAcc}
        />
      )}
      {showSavingsAccountsModal && (
        <ModalAccountsList
          title="Savings Account"
          accountsList={savingsAccounts}
          open={showSavingsAccountsModal}
          relationType="savings"
          onClose={() => {
            handleModalClose(AccountType.SAVING);
          }}
          setAccount={setSelectSavingsAcc}
        />
      )}
      {showDeleteAutomationModal && (
        <DeleteQuestionsModal
          onHide={() => {
            setShowDeleteAutomationModal(false);
          }}
          deleteAction={deleteAutomationHandler}
          type={"automation"}
        />
      )}
    </div>
  );
};

export default EditAutomation;
