import { useContext, useEffect, useRef, useState } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import { PLAID_LINK_NAMES, PLAID_LINK_TEXT } from "../../helpers/constants";
import {
  useLazyGetLinkTokenQuery,
  useAuthMutation,
  useActivatePlaidItemMutation,
} from "../../api/apiPlaid";
import Button from "../../elements/Button/Button";
import Loader from "../../elements/Loader/Loader";
import plus_btn_icon from "../../assets/plus-btn-icon.png";
import plus_white_icon from "../../assets/add_circle.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useMarkNotificationExpiredMutation } from "../../api/apiDashboard";
import {
  setReconnectError,
  setShowReconnectNotification,
} from "../../redux/sliceNotifications";
import { AuthContext } from "../../context/AuthContext";
// @ts-ignore
import PlaidPopup from "./PlaidPopup";
import { useMixpanel } from "../../helpers/mixpanel";

interface IPlaidAuthProps {
  buttonTitle?: string;
  buttonType?: string;
  success?: (results: any[]) => void;
  onExit?: () => void;
  preConnectedAccountsCB?: (account: any[], institution?: any) => void;
  setIsLoading?: (isLoading: boolean) => void;
  accountLoadErrorCallback?: (accountLoadMsg: string, type: string) => void;
  accountType?: string;
  typeBtn?: string;
  isOpen?: boolean;
  children?: JSX.Element;
  withoutMarkup?: boolean;
  accessToken?: string | null;
  reloadPage?: () => void;
  notifsCloseCallback?: () => void;
  relationType?: string | undefined;
}

function PlaidAuth({
  buttonTitle = "PLAID",
  buttonType = "secondary-btn",
  success = () => {},
  onExit = () => {},
  preConnectedAccountsCB = () => {},
  setIsLoading,
  accountLoadErrorCallback = () => {},
  accountType = "",
  typeBtn = "plaid-btn",
  isOpen,
  children,
  withoutMarkup = false,
  accessToken = null,
  reloadPage = () => {},
  notifsCloseCallback = () => {},
  relationType,
}: IPlaidAuthProps) {
  const context = useContext(AuthContext);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const relatedNotificationIDRef = useRef(null);
  const [plaidAuth, plaidAuthResult] = useAuthMutation({
    fixedCacheKey: "plaid",
  });
  const [activateItem, activateItemResult] = useActivatePlaidItemMutation({
    fixedCacheKey: "activateItem",
  });
  const [markNotification, markNotificationResult] =
    useMarkNotificationExpiredMutation({
      fixedCacheKey: "markNotification",
    });
  const [getLinkToken, getLinkTokenRes] = useLazyGetLinkTokenQuery();
  const [linkToken, setLinkToken] = useState(null);
  const [isShowPlaidPopup, setIsShowPlaidPopup] = useState(false);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const userNotifications: any = useSelector<RootState>(
    (state) => state.userNotifications?.userData?.notifications,
  );
  const successAuthResponse = plaidAuthResult?.data?.authResponse;
  const isPlaidAuthLoading = plaidAuthResult?.isLoading;
  const isMarkNotificationLoading = markNotificationResult?.isLoading;
  const isActivateItemLoading = activateItemResult?.isLoading;

  const sendMixpanelOpenEvent = (relationType: string | undefined) => {
    if (relationType === "checking") {
      mixpanel("Launched_Plaid_Checking");
    } else if (relationType === "savings") {
      mixpanel("Launched_Plaid_Savings");
    } else {
      mixpanel("Launched_Plaid");
    }
  };

  useEffect(() => {
    if (!linkToken) {
      getLinkToken({ accessToken: null });
    }
  }, [linkToken]);

  useEffect(() => {
    if (accessToken) {
      getLinkToken({ accessToken });
    }
  }, [accessToken]);

  useEffect(() => {
    if (typeof isOpen !== "boolean") {
      return;
    }
    if (isOpen === true) {
      sendMixpanelOpenEvent(relationType);
      setIsShowPlaidPopup(true);
      console.log("Plaid pop out should open now 1, linkToken:", linkToken);
    }
  }, [isOpen]);

  useEffect(() => {
    if (plaidAuthResult?.isError) {
      const error = plaidAuthResult?.error;
      if ("data" in error) {
        const data: any = error.data;
        const errMsg = "message" in data ? data.message : "";
        const techError: any = "techError" in data ? data.techError : {};
        if (errMsg && accountLoadErrorCallback) {
          accountLoadErrorCallback(errMsg, techError?.errorAccouNtType || "");
        }
      }
    }
  }, [plaidAuthResult]);

  useEffect(() => {
    if (getLinkTokenRes?.data) {
      const newLinkToken = getLinkTokenRes?.data?.token;
      setLinkToken(newLinkToken);
    }
  }, [getLinkTokenRes]);

  //send publicToken to DB where we save just linked accounts
  const sendTokenToBackend = (publicToken: string, metadata: any) => {
    if (isPlaidAuthLoading) {
      console.log(
        "sendTokenToBackend is terminated, isPlaidAuthLoading is:",
        isPlaidAuthLoading,
      );
      return;
    }
    const { user } = context;
    user?.getIdToken().then(async (tkn) => {
      await plaidAuth({
        publicToken,
        metadata,
        firebaseTkn: tkn,
      });
      plaidAuthResult.reset();
    });
  };

  //activate "broken" account + disable in-app notification
  const activateRelinkedItem = async () => {
    if (isMarkNotificationLoading || isActivateItemLoading) {
      return;
    }
    const { user } = context;
    const relatedNotificationID = relatedNotificationIDRef.current;
    user?.getIdToken().then(async (idToken: string) => {
      await activateItem({
        idToken,
        accessToken,
      });
      activateItemResult.reset();
      if (relatedNotificationID) {
        await markNotification({
          idToken,
          idNotification: relatedNotificationID,
        });
        markNotificationResult.reset();
      }
      console.log(`plaid item with token ${accessToken} successfully updated`);
      reloadPage();
    });
  };

  //--------------------------Plaid callbacks-----------------------------------------------//
  const onPlaidExit = (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata,
  ) => {
    try {
      setIsShowPlaidPopup(false);
      setLinkToken(null);

      if (notifsCloseCallback) {
        notifsCloseCallback();
      }
      onExit();
      if (error) {
        if (accessToken) {
          mixpanel(
            `${PLAID_LINK_TEXT}Bank_reconnect_failed_with_access_token`,
            { Error: error, metadata: metadata, accessToken: accessToken },
          );
        }
        mixpanel(`${PLAID_LINK_TEXT}Bank_connection_failed`, {
          Error: error,
          metadata: metadata,
        });
      }

      //code for error while linking+
      if (accessToken) {
        //case for updating accounts
        dispatch(setReconnectError(true));
        dispatch(setShowReconnectNotification(true));
      }
    } catch (error) {
      mixpanel(`${PLAID_LINK_TEXT}Bank_connection_failed`, {
        Error: error,
        metadata: metadata,
      });
      console.log("Error while executing error handler in PlaidAuth: ", error);
    }
  };

  const onPlaidSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ): void => {
    setIsShowPlaidPopup(false);
    setLinkToken(null);
    mixpanel(`${PLAID_LINK_TEXT}Success_Connected_Account`, {
      publicToken: publicToken,
    });
    try {
      if (accessToken) {
        //code for success account linking in update mode
        mixpanel(`${PLAID_LINK_TEXT}_Bank_reconnected`);
        activateRelinkedItem();
      }

      if (!accessToken && publicToken) {
        //code for success account linking in regular mode
        preConnectedAccountsCB(metadata.accounts, metadata.institution);
        sendTokenToBackend(publicToken, metadata);
      }
    } catch (error) {
      mixpanel(`${PLAID_LINK_TEXT}_Bank_connection_failed`, { Error: error });
      console.log(
        "Error while executing success handler in PlaidAuth: ",
        error,
      );
    }
  };

  const onPlaidEvent = (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata,
  ) => {
    if (PLAID_LINK_NAMES.includes(eventName)) {
      eventName = PLAID_LINK_TEXT + eventName;
    }
    mixpanel(eventName, metadata);
  };

  useEffect(() => {
    if (successAuthResponse) {
      const checkingList: any = [];
      const savingList: any = [];

      successAuthResponse.map((item: any) => {
        if (item.subtype == "checking") {
          checkingList.push(item.institution.name);
        }
        if (item.subtype == "savings") {
          savingList.push(item.institution.name);
        }
      });

      // TODO: dispatch Update data to Redux slicer

      mixpanel(`${PLAID_LINK_TEXT}Success`, successAuthResponse);
      success(successAuthResponse);
    }
  }, [successAuthResponse]);
  //-----------------------------------------------------------------------------------------------------//

  //Loader control
  useEffect(() => {
    const isLoading = Boolean(
      isPlaidAuthLoading || isMarkNotificationLoading || isActivateItemLoading,
    );
    setIsShowLoader(isLoading);
    if (setIsLoading) {
      setIsLoading(isLoading);
    }
  }, [isPlaidAuthLoading, isMarkNotificationLoading, isActivateItemLoading]);

  //Relink notification control
  useEffect(() => {
    if (!accessToken || !userNotifications) {
      return;
    }
    const relatedNotification = userNotifications.find((notification: any) => {
      if (
        !notification ||
        !notification.serviceData ||
        !notification.serviceData.accessToken
      ) {
        return false;
      }
      return accessToken === notification.serviceData.accessToken;
    });
    if (relatedNotification) {
      relatedNotificationIDRef.current = relatedNotification.id;
    }
  }, [accessToken, userNotifications]);

  if (isShowLoader) return <Loader loading={true} />;

  return (
    <>
      {isShowPlaidPopup && linkToken && (
        <PlaidPopup
          linkToken={linkToken}
          onPlaidSuccess={onPlaidSuccess}
          onPlaidExit={onPlaidExit}
          onPlaidEvent={onPlaidEvent}
        />
      )}
      {!withoutMarkup && children && (
        <div
          onClick={() => {
            sendMixpanelOpenEvent(relationType);
            setIsShowPlaidPopup(true);
            console.log(
              "Plaid pop out should open now 2, linkToken:",
              linkToken,
            );
          }}
        >
          {children}
        </div>
      )}
      {!withoutMarkup && !children && (
        <Button
          onClick={() => {
            sendMixpanelOpenEvent(relationType);
            setIsShowPlaidPopup(true);
            console.log(
              "Plaid pop out should open now 3, linkToken:",
              linkToken,
            );
          }}
          type={typeBtn}
          // style={{ width: "100%" }}
          // disabled={!ready}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            {typeBtn === "modalPlaid-btn" ? (
              <img src={plus_white_icon} alt="plus icon" />
            ) : (
              <img src={plus_btn_icon} alt="plus icon" />
            )}
            <div style={{ flex: "1" }}>{accountType}</div>
          </div>
        </Button>
      )}
    </>
  );
}

export default PlaidAuth;
