import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { auth } from "../../config/firebase";
import { useLazyGetUserRegistrationStepQuery } from "../../api/apiAuth";
import { updateInfo } from "../../redux/sliceAuth";
import {
  getStepNumberForRegistration,
  REGISTRATION_STEPS,
} from "../../helpers/redirectToRegistrationStep";
import StepWizard from "react-step-wizard";
import SignUpEmail from "./SignUpEmail/SignUpEmail";
import SignUpPassword from "./SignUpPassword/SignUpPassword";
import SignUpName from "./SignUpName/SignUpName";
import AddAutomationInstruction from "./AddAutomationInstruction/AddAutomationInstruction";
import AddAutomation from "./AddAutomation/AddAutomation";
import AutomationFeedback from "./AutomationFeedback/AutomationFeedback";
import SignUpAddress from "./SignUpAddress/SignUpAddress";
import SignUpDateBirth from "./SignUpDateBirth/SignUpDateBirth";
import SignUpSSN from "./SignUpSSN/SignUpSSN";
import SignUpVerifyEmail from "./SignUpVerifyEmail/SignUpVerifyEmail";
import Loader from "../../elements/Loader/Loader";
import { signOut } from "firebase/auth";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { useLazyGetAutomationsDataQuery } from "../../api/apiDashboard";
import {
  setBalanceMaxValue,
  setCheckingAcc,
  setSavingsAcc,
  setSignUpAutomationId,
} from "../../redux/sliceUserAutomation";
import { AuthContext } from "../../context/AuthContext";
import { useMixpanelPublic } from "../../helpers/mixpanel";

function SignUp() {
  const context = useContext(AuthContext);
  const { user } = context;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const [getRegistrationStep, registrationStepData] =
    useLazyGetUserRegistrationStepQuery();
  const [step, setStep] = useState(REGISTRATION_STEPS.INTRO);
  const [loading, setLoading] = useState(true);
  const mixpanel = useMixpanelPublic();

  const userData: any = useSelector<RootState>(
    (state) => state?.userNotifications?.userData,
  );

  useEffect(() => {
    if (!registrationStepData.data) {
      return;
    }

    const step = registrationStepData?.data?.step;

    if (step === REGISTRATION_STEPS.COMPLETED) {
      navigation("/automations");
      return;
    }
    if (
      [REGISTRATION_STEPS.REMOVED, REGISTRATION_STEPS.NOT_DEFINED].includes(
        step,
      )
    ) {
      signOut(auth)
        .then(() => {
          // notification({
          //   message:
          //     step === REGISTRATION_STEPS.REMOVED
          //       ? "User has been removed"
          //       : "Ooosp, something went wrong, try again...",
          //   type: "error",
          // });
          navigation("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (step === REGISTRATION_STEPS.VERIFY_DWOLLA_RETRY) {
      navigation("/signup-retry-verify");
    }
    if (step === REGISTRATION_STEPS.VERIFY_DWOLLA_FAILED) {
      navigation("/signup-unable-verify");
    } else {
      setStep(step);
    }
  }, [registrationStepData.data]);

  useEffect(() => {
    if (registrationStepData.isError) {
      signOut(auth)
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [registrationStepData.isError]);

  useEffect(() => {
    if (!authInfo.email) {
      if (userData) {
        const {
          address,
          street,
          apartment,
          birthday,
          city,
          email,
          firstName,
          lastName,
          ssn,
          state,
          zipCode,
          confirmedCachePolicy,
        } = userData;

        const ssnNumbers = ssn ? ssn.split("").map(Number) : "";
        const birthdayDate = birthday ? new Date(birthday) : "";

        dispatch(
          updateInfo({
            email,
            address,
            street,
            apartment,
            birthday,
            birthdayDay: birthdayDate ? birthdayDate.getDate() : "",
            birthdayMonth: birthdayDate ? birthdayDate.getMonth() + 1 : "",
            birthdayYear: birthdayDate ? birthdayDate.getFullYear() : "",
            city,
            firstName,
            lastName,
            ssnSix: ssnNumbers[0],
            ssnSeven: ssnNumbers[1],
            ssnEight: ssnNumbers[2],
            ssnNine: ssnNumbers[3],
            state,
            zipCode,
            confirmedCachePolicy,
          }),
        );
      }
    }
  }, [userData]);

  const handleRegistrationStep = useCallback(async () => {
    try {
      const idToken = await user?.getIdToken();
      if (!idToken) {
        return;
      }
      await getRegistrationStep({ idToken }, false);
      setLoading(false);
    } catch (error) {
      const isRequestFailed = `${error}`.includes(
        "auth/network-request-failed",
      );
      if (isRequestFailed) {
        try {
          await mixpanel("Network_Request_Failed", {
            userName: user?.displayName || "N/A",
            userEmail: user?.email || "N/A",
            pageLocation: window.location?.pathname || "N/A",
            layout: "Shared_Signup",
            errorMsg: `${error}`,
          });
        } catch (error) {
          console.log("mixpanel event failed");
        }
      }
    }
  }, [context]);

  useEffect(() => {
    if (!user) {
      setLoading(false);
    } else {
      handleRegistrationStep();
    }
  }, [context]);

  if (loading) {
    return <Loader loading={true} />;
  }

  const handleRegistrationStepNumber = (): number => {
    if (!auth.currentUser) {
      return 1;
    }
    const stepNumber = getStepNumberForRegistration(step);
    if (stepNumber === 0) {
      signOut(auth)
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          console.log(
            "Error in getStepNumberForRegistration SignUp.tsx:",
            error,
          );
        });
      return 1;
    }
    return stepNumber;
  };

  return (
    <StepWizard
      initialStep={handleRegistrationStepNumber()}
      transitions={{
        enterRight: "",
        enterLeft: "",
        exitRight: "",
        exitLeft: "",
      }}
    >
      <SignUpName />
      <SignUpEmail />
      <SignUpPassword />
      <AddAutomationInstruction />
      <AddAutomation />
      <AutomationFeedback />
      <SignUpDateBirth />
      <SignUpAddress />
      <SignUpSSN />
      <SignUpVerifyEmail />
      <SignUpEmail />
    </StepWizard>
  );
}

export default SignUp;
