export const PLAID_LINK_NAMES = [
  "Launched_Plaid_Checking",
  "Launched_Plaid_Savings",
  "OPEN",
  "TRANSITION_VIEW",
  "SELECT_INSTITUTION",
  "OPEN_OAUTH",
  "HANDOFF",
  "ERROR",
  "Plaid_Success_Connected_Account",
  "EXIT",
  "SUBMIT_CREDENTIALS",
];
export const PLAID_LINK_TEXT = "PlaidLink_";

export const WEALTHFRONT_INSTITUTION = "ins_115617";

